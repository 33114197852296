/* Globals */

:root  {
  /* https://projects.invisionapp.com/boards/HE2VVROFSGB27 */
  --brand-background: #270F34;
  --brand-lines: #4B3457;
  /* Blockstack colors */
  --brand-dark: #211F6D;
  --brand-mild: #C4D8E5;
  --brand-greyish: #F1F6F9;
  --brand-bright: #3700FF;
  --brand-accent: #00D4FF;
}

html,body{min-height:100vh;}

:root {
  /* Holiday palette
     https://www.schemecolor.com/christmas-red-and-green.php */
  --red: #B3000C;
  --pink: #FF0012;
  --light: #D8D8D8;
  --green: #00B32C;
  --yellow: #00FF3E;
}

:root {
  --primary: var(--green) !important;
}

.navbar-dark {
  background-color: var(--red) !important;
}

.text-light {
  color: var(--light) !important;
}

.btn-primary {
   background-color: var(--green) !important;
   border-color: var(--green) !important;
}

.btn-primary:hover {
   border-color: var(--yellow) !important;
 }

 .btn-outline-primary {
    border-color: var(--green) !important;
    color: var(--green) !important;
 }

.btn-outline-primary:hover, .btn-outline-secondary:hover {
    border-color: var(--yellow) !important;
    background-color: var(--green) !important;
    color: var(--light) !important;
  }

.btn-outline-secondary:hover .text-info {
  color: var(--light) !important;
}

.input-group-text {
  background-color: var(--light) !important;
}



/* NavBar */

.navbar .logo {
  max-height: 32px;
  margin-right: 1em;
  color: red;
}

/* Avatar */
.avatar{width:100px;height:100px; border-radius: 50%}
.avatar-section{margin:25px;}

/* Scaffolding */
.site-wrapper{width:100%;height:90% !important;min-height:90vh !important;}
.site-wrapper-inner{display:flex;flex-direction:column;justify-content:center;margin-right:auto;margin-left:auto;width:100%;height:90%;}

.plus {
  margin-top: 1em;
  float: right;
}

.NoteField {
  margin-bottom: 0.5em;
}
